import {
  ChangeProposal,
  ReadableChangeProposalStatus,
} from '@payaca/types/changeProposalTypes';
import { JobContent, JobLineItem } from '@payaca/types/jobContentTypes';
import { BadgeColourVariant } from '@payaca/types/plBadge';
import { StatusBadgeState } from './statusBadgeHelper';

// works for JLIs onm V2 deals only - ignores markup and discount
export const getTotalsFromJobLineItems = (jobLineItems: JobLineItem[]) => {
  return jobLineItems.reduce(
    (acc, jobLineItem) => {
      const subtotal = jobLineItem.price * jobLineItem.quantity;

      return {
        subtotal: acc.subtotal + subtotal,
        discountTotal: (acc.discountTotal += jobLineItem.discountAmount || 0),
        markupTotal: (acc.markupTotal += jobLineItem.markupAmount || 0),
        vatTotal: acc.vatTotal + jobLineItem.vatTotal,
        cisTotal: acc.cisTotal + (jobLineItem.cisTotal || 0),
        total: acc.total + jobLineItem.total,
      };
    },
    {
      subtotal: 0,
      markupTotal: 0,
      discountTotal: 0,
      vatTotal: 0,
      cisTotal: 0,
      total: 0,
    }
  );
};

export const getDealValueForChangeProposal = (
  activeJobContent: JobContent | undefined,
  changeProposals: ChangeProposal[],
  changeProposalId: number
): number => {
  if (activeJobContent) {
    const sortedChangeProposals = changeProposals
      .filter((cp) => !!cp.acceptedAt && !cp.voidedAt && !cp.declinedAt) // filter out non-accepted, voided, declined
      .sort(
        (a: ChangeProposal, b: ChangeProposal) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      ); // sort by createdAt

    const currentChangeProposalIdx = sortedChangeProposals.findIndex(
      (cp) => cp.id === changeProposalId
    );
    // sum job content and all cps up to and inc current cp
    const totalToCp = sortedChangeProposals
      .slice(0, currentChangeProposalIdx + 1)
      .reduce(
        (acc, curr) => acc + curr.valueChangeIncTax,
        activeJobContent.total
      );
    return totalToCp;
  }
  return 0;
};

export const isSentChangeProposal = (changeProposal: ChangeProposal): boolean =>
  !!changeProposal.sentAt;

export const canMarkChangeProposalAsAccepted = (
  changeProposal: ChangeProposal
): boolean => {
  return (
    isSentChangeProposal(changeProposal) &&
    !changeProposal.acceptedAt &&
    !changeProposal.declinedAt
  );
};

export const canMarkChangeProposalAsDeclined = (
  changeProposal: ChangeProposal
) => {
  return (
    isSentChangeProposal(changeProposal) &&
    !changeProposal.acceptedAt &&
    !changeProposal.declinedAt
  );
};
export const canMarkChangeProposalAsVoid = (changeProposal: ChangeProposal) => {
  return !changeProposal.acceptedAt;
};

export const getChangeProposalStatusBadgeState = (
  readableStatus: ReadableChangeProposalStatus
) => {
  switch (readableStatus) {
    case ReadableChangeProposalStatus.ACCEPTED:
      return StatusBadgeState.GREEN_SUCCESS;
    case ReadableChangeProposalStatus.VOID:
    case ReadableChangeProposalStatus.DECLINED:
      return StatusBadgeState.RED_FAILURE;
    case ReadableChangeProposalStatus.DRAFT:
      return StatusBadgeState.GREY_INITIAL;
    default:
      return StatusBadgeState.BLUE_NEUTRAL;
  }
};

export const getChangeProposalPLBadgeState = (
  readableStatus?: ReadableChangeProposalStatus
): BadgeColourVariant => {
  switch (readableStatus) {
    case ReadableChangeProposalStatus.ACCEPTED:
      return 'teal';
    case ReadableChangeProposalStatus.VOID:
    case ReadableChangeProposalStatus.DECLINED:
      return 'red';
    case ReadableChangeProposalStatus.DRAFT:
      return 'black';
    default:
      return 'blue';
  }
};
