import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import * as authActions from '@payaca/store/auth/authActions';

import { ConnectionControl } from '@/ui/components';

import { useSelector } from '@/api/state';
import { Link } from 'react-router-dom';
import ZapierLogo from './zapier.png';

const ZapierConnectionControl: FunctionComponent = (): JSX.Element | null => {
  const dispatch = useDispatch();

  const hasZapierFeature = useSelector((state) => {
    const accountAccessInformation = state.account.accountAccessInformation;
    return !!accountAccessInformation?.features?.hasZapier;
  });

  const zapierConnection = useSelector((state: any) => {
    if (!state.users.myProfile.oAuthConnections) {
      return null;
    }
    return state.users.myProfile.oAuthConnections.find(
      (p: any) => p.clientName === 'Zapier' || p.clientName === 'zapier test'
    );
  });

  return (
    <ConnectionControl
      disableConnection={!hasZapierFeature}
      disableConnectionMessage={
        <p className="text-red-600">
          {zapierConnection ? (
            <>
              Any zaps you have setup will not run, as you do not have the
              correct subscription.{' '}
              <Link to="/upgradeAccount">Upgrade here to resume your zaps</Link>
            </>
          ) : (
            <>
              Available on the Team plan and above.{' '}
              <Link to="/upgradeAccount">
                Upgrade here to start using Zapier
              </Link>
            </>
          )}
        </p>
      }
      connectionName="Zapier"
      isConnected={zapierConnection}
      onRemoveConnection={(onConnectionRemoved: () => void) =>
        dispatch(
          authActions.removeOAuthConnection(
            zapierConnection,
            onConnectionRemoved
          )
        )
      }
      allowConnectDisconnect={true}
      disconnectionError="Sorry, there was an error removing your connection with Zapier. Please try again or head over to your Zapier account and remove your connection with Payaca."
      logo={ZapierLogo}
      onAddConnection={() =>
        window.open(
          import.meta.env.VITE_ENV === 'staging' ||
            import.meta.env.VITE_NODE_ENV === 'development'
            ? 'https://zapier.com/developer/public-invite/125577/2765a5b758bd096b961fbc6995e39164/'
            : 'https://zapier.com/apps/payaca/integrations'
        )
      }
    >
      <p>
        Automate workflows by connecting Payaca with thousands of apps using
        Zapier, streamlining your processes across platforms with ease.
      </p>
    </ConnectionControl>
  );
};

export default ZapierConnectionControl;
